import { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import {
  Box,
  Grid,
  Paper,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import RemoveShoppingCartOutlinedIcon from "@mui/icons-material/RemoveShoppingCartOutlined";
import { TbTrash } from "react-icons/tb";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TopBar from "../../components/topNav";
import { userRequest } from "../../requestMethod";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { formatPrice } from "../../utils/helpers";
import ModalContent from "./Modal";
import { useSelector } from "react-redux";

const Cart = () => {
  const [trigger, setTrigger] = useState(false);
  const [userCart, setUserCart] = useState<any[]>([]);
  const [priceSummary, setPriceSummary] = useState<any>([]);
  const [, setPriceData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [slotNums, setSlotNums] = useState<any>({});
  const [quantity, setQuantity] = useState(1);
  const userData = useSelector((state: any) => state.login.userData);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    Cookies.remove("slots");
    
    const getUserCart = async () => {
      try {
        setIsLoading(true);
  
        // Fetch cart items
        const cartResponse = await fetch(`${baseUrl}/user/carts/items`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.data.token}`,
          },
        });
  
        if (!cartResponse.ok) {
          throw new Error('Failed to fetch cart items');
        }
  
        const cartData = await cartResponse.json();
        setUserCart(cartData?.data);
        setQuantity(cartData?.data[0]?.quantity);
  
        // Fetch order summary
        const summaryResponse = await fetch(`${baseUrl}/user/carts/order-summary`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.data.token}`,
          },
        });
  
        if (!summaryResponse.ok) {
          throw new Error('Failed to fetch order summary');
        }
  
        const summaryData = await summaryResponse.json();
        setPriceSummary(summaryData?.data);
  
        // Function to calculate and set summary
        const setSummary = (data: any) => {
          let subTotal = 0;
          let fees = 0;
          let taxes = 0;
          let total = 0;
          let slotNum = slotNums;
  
          data.forEach((element: any) => {
            if (element.property) {
              subTotal += element.property.costings.per_Slot;
              fees += element.property.costings.maihomm_fee;
              taxes += element.property.costings.legal_and_closing_cost;
              total += element.property.costings.property_acq_cost;
              slotNum[element.property.id] = 1;
            }
          });
  
          setSlotNums(slotNum);
          setPriceData({
            subTotal,
            fees,
            taxes,
            total,
          });
        };
  
        setSummary(cartData?.data);
      } catch (err: any) {
        toast.error(err.message || 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };
  
    getUserCart();
  }, [trigger, slotNums]);
  


  const removeCartItem = async (id: string) => {
    try {
      const response = await fetch(`${baseUrl}/user/carts/items/remove/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData?.data.token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to remove item with id ${id}`);
      }
  
      setTrigger((prev) => !prev);
      console.log(response, "base");
    } catch (err) {
      console.log('Error:', err);
    }
  };
  

  const handleSlots = async (id: number, operation: string) => {
    let q = quantity;
    if (operation === "increment") {
      q++;
    } else if (operation === "decrement") {
      q--;
    }
  
    setQuantity(q);
  
    try {
      // Update the quantity of a cart item
      const updateResponse = await fetch(`${baseUrl}/user/carts/items/update/${id}/${q}`, {
        method: 'GET', // Use PUT or PATCH if needed based on your API
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData?.data.token}`,
        },
      });
  
      if (!updateResponse.ok) {
        throw new Error(`Failed to update item quantity for item ${id}`);
      }
  console.log(updateResponse, "base")
      // Fetch the updated order summary
      const summaryResponse = await fetch(`${baseUrl}/user/carts/order-summary`, {
        method: 'GET', // Order summary is likely a GET request
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData?.data.token}`,
        },
      });
  
      if (!summaryResponse.ok) {
        throw new Error('Failed to fetch order summary');
      }
  
      const summaryData = await summaryResponse.json();
      console.log(summaryData, "base")
      setPriceSummary(summaryData?.data?.data); // Update price summary state
  
    } catch (err) {
      console.error('Error:', err);
    }
  };
  

 

  const [popup, setPopup] =useState (false)

  const handleModal = () => {
    setPopup(!popup)
  }
  const closeModal = () => setPopup(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Set a timeout to change the loading state after 2 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Cleanup the timeout if the component is unmounted before the timeout completes
    return () => clearTimeout(timer);
  }, []);
  const total = priceSummary.sub_total + priceSummary.fee + priceSummary.sub_total;
  return (
    <Wrapper>
      {
        loading ? (
          <div className="flex-1 flex justify-center items-center">
          <CircularProgress size={24} />
        </div>
        ):(
          <div className="container mx-auto p-4">
            <div className="flex flex-col gap-4">
              {!isLoading ? (
                <div className="flex-1 w-full overflow-x-auto">
                  {userCart.length >= 1 && (
                    <table className="lg:w-full w-[200%]">
                      <thead className="bg-[#1E1E1E]">
                        <tr>
                          <th className="py-4 px-4 text-[#9A9FA5] font-jakarta text-[14px] text-start">Property</th>
                          <th className="py-4 px-4 text-[#9A9FA5] font-jakarta text-[14px] text-start">Price per Slot</th>
                          <th className="py-4 px-4 text-[#9A9FA5] font-jakarta text-[14px] text-start">No of Slot</th>
                          <th className="py-4 px-4 text-[#9A9FA5] font-jakarta text-[14px] text-start">Total per Slot</th>
                          <th className="py-4 px-4 text-[#9A9FA5] font-jakarta text-[14px] text-start"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {userCart.map((item) =>
                          item.property ? (
                            <tr key={item.property.id}>
                              <td className="py-2 px-4 border-b border-b-[#33383F]">
                                <div className="flex items-center">
                                  <div className="w-20 h-20 overflow-hidden rounded">
                                    {item?.property?.default_image?.url && (
                                      <img
                                        src={item.property.default_image.url}
                                        alt="home"
                                        className="object-cover w-full h-full"
                                      />
                                    )}
                                  </div>
                                  <div className="ml-2">
                                    <a
                                      href={`/properties/${item.property.id}`}
                                      className="text-white font-jakarta text-[14px] no-underline"
                                    >
                                      {item.property.name}
                                    </a>
                                    <p className="text-gray-500 font-jakarta text-[14px]">
                                      {item.property.location.address}
                                    </p>
                                   
    
                                  </div>
                                </div>
                              </td>
                              <td className="py-2 px-4 text-[14px] font-jakarta border-b border-b-[#33383F]">
                                {formatPrice(item.property.costings.per_Slot)}
                              </td>
                              <td className="py-2 px-4 border-b font-jakarta border-b-[#33383F]">
                                <div className="flex items-center justify-center gap-2 border border-[#33383F] py-[10px] rounded-[10px]">
                                  <RemoveIcon
                                    onClick={() =>
                                      quantity > 1
                                        ? handleSlots(item.property.id, "decrement")
                                        : ""
                                    }
                                    className="cursor-pointer text-[14px] text-[#9A9FA5]"
                                  />
                                  <div className="flex items-center">
                                    <span className="text-[14px] font-jakarta  text-[#9A9FA5]">{quantity}</span>
                                    {/* <span className="text-sm">slot(s)</span> */}
                                  </div>
                                  <AddIcon
                                    onClick={() =>
                                      quantity + 1 <=
                                        item.property.costings.available_slots
                                        ? handleSlots(item.property.id, "increment")
                                        : ""
                                    }
                                    className="cursor-pointer font-jakarta text-[#9A9FA5] text-[14px]"
                                  />
    
                                </div>
                              </td>
                              <td className="py-2 px-4 text-[14px] font-jakarta border-b border-b-[#33383F]">
                                {formatPrice(item.property.costings.per_Slot * quantity)}
                              </td>
                              <td
                                className="py-2 px-4 border-b font-jakarta cursor-pointer border-b-[#33383F]"
                                onClick={() => removeCartItem(item.property.id)}
                              >
                                <TbTrash />
                              </td>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              ) : (
               ""
              )}
              
              <div className="flex flex-row lg:justify-end ">
              
                <div className="lg:w-[40%] w-full rounded-lg shadow">
                  <h6 className="text-[16px] text-[#9A9FA5] p-4 font-jakarta bg-[#1E1E1E]">DEAL TOTAL</h6>
                  <div className="border border-[#1E1E1E] py-4 px-4">
                  <div className="flex justify-between items-center my-3">
                    <p className="text-[14px] text-gray-500 font-jakarta">Subtotal</p>
                    <p className="text-[14px] font-jakarta">{priceSummary?.sub_total && formatPrice(priceSummary.sub_total)}</p>
                  </div>
                  <div className="flex justify-between items-center my-3">
                    <p className="text-[14px] font-jakarta text-gray-500">Promo discount</p>
                    <p className="text-[14px] font-jakarta">{priceSummary?.fees && formatPrice(priceSummary.fees)}</p>
                  </div>
                
                  <div className="flex justify-between items-center my-3">
                    <p className="text-[14px] font-jakarta text-gray-500">Total</p>
                    <p className="text-[14px] font-jakarta">{priceSummary?.total && formatPrice(priceSummary.total)}</p>
                  </div>
                  <button className="bg-[#DEA838] font-jakarta p-3 rounded-[10px] text-black w-full text-center" onClick={handleModal}>
                    Proceed to payment
                  </button>
                   
                 
                  </div>
                </div>
              </div>
            </div>
          </div>

        )
      }
      {popup && (
                  <div
                    className="fixed inset-0  flex justify-center items-center bg-black bg-opacity-50"
                    // onClick={closeModal}
                  >
                    <div
                      className="bg-transparent w-[20%]"
                      onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
                    >
                      <ModalContent 
                      close={closeModal}
                      userCart={userCart}
                      total={priceSummary.total}
                      />
                      
                    </div>
                  </div>
                )}
    </Wrapper>
  );
};

export default Cart;
