import FirstCard from "./firstcard";
import SecondCard from "./secondcard";
import RecentTransaction from "./recenttransaction";
import FutureProperty from "./futureProperty";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethod";
import { useSelector } from "react-redux";

const UserOverview = () => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const userData = useSelector((state: any) => state.login.userData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userRequest.get("/user/overviews", {
          headers: {
            Authorization: `Bearer ${userData?.data?.token}`,
            "Content-Type": "application/json",
          },
        });
        console.log(response.data, "API response");
        setData(response.data?.data || {}); // Ensure data is always an object
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    if (userData?.data?.token) {
      fetchData();
    }
  }, [userData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <div>
      <FirstCard data={data} />
      <SecondCard
        data={data.booking || []} // Fallback to empty array if booking is null or undefined
        myproperty={data.my_properties || []} // Fallback to empty array if my_properties is null or undefined
      />
      {/* Uncomment if RecentTransaction is needed */}
      {/* <RecentTransaction data={data} /> */}
      <FutureProperty data={data} />
    </div>
  );
};

export default UserOverview;

