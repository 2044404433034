import FirstCard from "./firstcard";
import SecondCard from "./secondcard";
import RecentTransaction from "./recenttransaction";
import FutureProperty from "./futureProperty";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethod";
import { useSelector } from "react-redux";

const UserOverview = () => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true); // Optional: for loading state

  const userData = useSelector((state: any) => state.login.userData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userRequest.get('/user/overviews', {
          headers: {
            Authorization: `Bearer ${userData?.data?.token}`,
            "Content-Type": "application/json",
          },
        });
        setData(response.data?.data); // Set the response data to state
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false); // Stop loading when the request is complete
      }
    };

    fetchData();
  }, [userData]); // Include userData in dependency array
console.log(data, "over")
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <>
      {data && (
        <>
          <FirstCard data={data} />
          <SecondCard  data={data?.booking}  myproperty={data?.my_properties}/>
          {/* <RecentTransaction  data={data}/> */}
          <FutureProperty  data={data}/>
        </>
      )}
    </>
  );
};

export default UserOverview;
