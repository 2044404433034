import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

// const BASE_URL: string = process.env.REACT_APP_BASE_URL;
//  const BASE_URL: string = "https://maihom-5b675cef6fe3.herokuapp.com/api"; 

const storedUserData: string | null = localStorage.getItem('userToken');

// const user = Cookies.get('userData') || Cookies.get('pendingUser');
// const currentUser = user && JSON.parse(user);
const tkn: string | null = storedUserData;

console.log(tkn, "TOKK");

export const publicRequest = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

export const userRequest = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        Authorization: tkn ? `Bearer ${tkn}` : '',
        "Content-Type": "multipart/form-data",
    },
});
